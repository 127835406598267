/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import RootProvider from "./src/components/root-provider"

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element }) => {
  return <RootProvider>{element}</RootProvider>
}
